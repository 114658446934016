






























import { Component, Vue } from 'vue-property-decorator';

import ProblemPage from '@/assets/imgs/maintenance/ProblemPage.vue';

import WrapperInstability from '@/components/WrapperInstability/WrapperInstability.vue';

import instability from '@/mixins/Instability';

const DESCRIPTION_B2C = 'Infelizmente no momento estamos enfrentando um problema no serviço. Aproveite para revisar assistindo a alguns de nossos conteúdos no YouTube ou tente novamente mais tarde.';
const DESCRIPTION_B2B = 'Infelizmente no momento estamos enfrentando um problema no serviço.  Tente novamente mais tarde.';

@Component({
  mixins: [instability],
  components: {
    ProblemPage,
    WrapperInstability,
  },
})
export default class Problem extends Vue {
  get isB2b() {
    return !!this.$store.getters.profile.institution?.name;
  }

  get description() {
    return this.isB2b ? DESCRIPTION_B2B : DESCRIPTION_B2C;
  }

  redirectToDashboard() {
    this.$router.push({
      name: 'Dashboard',
    });
  }
}
